const endpoints = {
    navbar: 'profile/navbar.json',
    routes: 'profile/routes.json',
    home: 'profile/home.json',
    social: 'profile/social.json',
    about: 'profile/about.json',
    experiences: 'profile/experiences.json',
    projects: 'profile/projects.json',
    auth: 'https://auth.inetfly.ru/realms/inetfly',
};

export default endpoints;
