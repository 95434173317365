import React from 'react';
import {DarkMode} from "use-dark-mode";

export type ContextProps = {
    darkMode: DarkMode
};

const AppContext = React.createContext<Partial<ContextProps>>({});

export default AppContext;
