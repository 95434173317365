import React, {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import Fade from 'react-reveal';
import endpoints from '../constants/endpoints';
import SocialComponent from './SocialComponent';
import FallbackSpinner from './FallbackSpinner';
import {Home, IHome} from '../model/HomeModel';
import ReactMarkdown from "react-markdown";

const styles = {
    nameStyle: {
        fontSize: '5em',
    },
    subTitleStyle: {
        fontSize: '2em',
    },
    inlineChild: {
        display: 'inline-block',
    },
    mainContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    pageContainer: {
        textAlign: 'left' as const,
    },
    imgStyle: {
        display: 'flex',
        //margin: 'right',
        height: 200,
    }
};

function HomeComponent() {
    const [data, setData] = useState<Home>();
    useEffect(() => {
        axios
            .get(endpoints.home)
            .then((res: AxiosResponse<IHome>) => {
                setData(new Home(res.data));
            })
    }, []);

    return data ? (
        <Fade>
            <div style={styles.mainContainer}>
                <h1 style={styles.nameStyle}>{data?.name}</h1>
                <h2 style={styles.subTitleStyle}>{data?.subTitle}</h2>
                <div style={styles.pageContainer}>
                    <img src={data?.img} alt="logo" style={styles.imgStyle}/>
                    <ReactMarkdown children={data?.text}/>
                </div>
                <SocialComponent/>
            </div>
        </Fade>
    ) : <FallbackSpinner/>;
}

export default HomeComponent;
