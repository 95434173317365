export interface IRoutes {
    sections: IRoutesSections[];
}

export class Routes implements IRoutes {
    private _sections: RoutesSections[];

    constructor({sections}: IRoutes) {
        this._sections = sections.map((s) => new RoutesSections(s));
    }

    get sections(): RoutesSections[] {
        return this._sections;
    }

    set sections(value: RoutesSections[]) {
        this._sections = value;
    }
}

export interface IRoutesSections {
    component: string | undefined;
    path: string | undefined;
    headerTitle: string | undefined;
}

export class RoutesSections implements IRoutesSections {
    private _component: string | undefined;
    private _headerTitle: string | undefined;
    private _path: string | undefined;

    constructor(data: IRoutesSections) {
        Object.assign(this, data)
    }

    get component(): string | undefined {
        return this._component;
    }

    set component(value: string | undefined) {
        this._component = value;
    }

    get headerTitle(): string | undefined {
        return this._headerTitle;
    }

    set headerTitle(value: string | undefined) {
        this._headerTitle = value;
    }

    get path(): string | undefined {
        return this._path;
    }

    set path(value: string | undefined) {
        this._path = value;
    }
}