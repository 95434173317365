export interface ISocial {
    social: ISocialItem[];
}

export interface ISocialItem {
    network: string;
    href: string;
}

export class Social implements ISocial {
    private _social: SocialItem[];

    constructor({ social }: ISocial) {
        this._social = social.map((s) => new SocialItem(s));
    }

    get social(): SocialItem[] {
        return this._social;
    }

    set social(value: SocialItem[]) {
        this._social = value;
    }
}

export class SocialItem implements ISocialItem {
    private _network: string;
    private _href: string;

    constructor(data: ISocialItem) {
        Object.assign(this, data)
    }
    get href(): string {
        return this._href;
    }

    set href(value: string ) {
        this._href = value;
    }
    get network(): string {
        return this._network;
    }

    set network(value: string ) {
        this._network = value;
    }

}