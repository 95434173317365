export interface IHome {
    name: string;
    subTitle: string;
    roles: string[];
    img: string;
    text: string;
}

export class Home implements IHome {
    private _img: string;
    private _name: string;
    private _roles: string[];
    private _subTitle: string;
    private _text: string;

    constructor({name, subTitle, roles, img, text}: IHome) {
        this._img = img;
        this._name = name;
        this._roles = roles;
        this._subTitle = subTitle;
        this._text = text;
    }


    get img(): string {
        return this._img;
    }

    set img(value: string) {
        this._img = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get subTitle(): string {
        return this._subTitle;
    }

    set subTitle(value: string) {
        this._subTitle = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }
}