import React from 'react';
import DarkModeToggle from 'react-dark-mode-toggle';
import PropTypes from 'prop-types';
import AppContext from '../AppContext';

function ThemeToggleComponent(props) {
    const { onClick } = props;
    const handleOnChange = (darkMode) => {
        darkMode.toggle();
        onClick();
    };

    return (
            <AppContext.Consumer>
                {(value) => (
                    <div style={{ marginBottom: 8 }}>
                        <DarkModeToggle
                            onChange={() => handleOnChange(value.darkMode)}
                            checked={value.darkMode?.value}
                            size={50}
                        />
                    </div>
                )}
            </AppContext.Consumer>
    );
}

ThemeToggleComponent.propTypes = {
    onClick: PropTypes.func,
};
ThemeToggleComponent.defaultProps = {
    onClick: () => {},
};

export default ThemeToggleComponent;
