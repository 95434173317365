import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import useDarkMode from 'use-dark-mode';
import AppContext from './AppContext';
import MainApp from './MainApp';
import GlobalStyles from './theme/GlobalStyles';
import {darkTheme, lightTheme} from './theme/themes';

function App() {
    // @ts-ignore
    window.matchMedia = null;
    const darkMode = useDarkMode(false);

    return (
        <AppContext.Provider value={{darkMode}}>
            <ThemeProvider theme={darkMode.value ? darkTheme : lightTheme}>
                <GlobalStyles/>
                <div className="App">
                    <BrowserRouter>
                        <MainApp/>
                    </BrowserRouter>
                </div>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
